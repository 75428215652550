import { createI18n } from "vue-i18n";

function loadLocaleMessages() {
    const locales = require.context( "@/locales", true, /[A-Za-z0-9-_,\s]+\.json$/i );
    const messages = {};
    locales.keys().forEach((key) => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i);
        const locale = matched[1];
        messages[locale] = require(`./locales/${locale}.json`);
    });
    return messages;
}

export default createI18n({
    legacy: false,
    globalInjection: true,
    locale: "es",
    fallbackLocale: "es",
    messages: loadLocaleMessages(),
});