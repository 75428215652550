<template>
  <div class="mobile-menu" v-bind:class="{ 'hidden-mobile': !showComponent }">
    <div class="mobile-menu__container">
      <router-link class="mobile-menu__item" :to="opcion.link"  v-for="(opcion, index) in menuOptions" :key="index">
        <img class="mobile-menu__item-casino" :src="opcion.img" :alt="opcion.alt" >
        <span>{{ opcion.title }}</span>
      </router-link>
    </div>
    <!-- <div class="mobile-menu__buttons" @click="openModalClassic">
      <span class="material-symbols-rounded">person</span>
      <a href="#" class="btn-login secondary-button mobile-menu__login">Classic</a>
    </div>
    <div class="mobile-menu__buttons" @click="openModal">
      <span class="material-symbols-rounded">person</span>
      <a href="#" class="btn-login secondary-button mobile-menu__login">Betslip</a>
    </div> -->
    <menu-component />
    <!-- <ModalComponent ref="modal" />
    <ModalClassicComponent ref="modalClassic" /> -->
  </div>
</template>
  
<script>
  import MenuComponent from '@/components/menu.vue'
  // import ModalComponent from '@/components/modal.vue'
  // import ModalClassicComponent from '@/components/modal-classic.vue'
  // import { createRouter, createWebHistory } from 'vue-router';
  import { reactive, watch } from 'vue';
  import { useI18n } from 'vue-i18n';
  export default {
    name: 'MobileMenu',
    showComponent: true,
    components: {
      MenuComponent,
      // ModalComponent,
      // ModalClassicComponent
    },
    setup() {
      const { t, locale }  = useI18n();
      const menuOptions = reactive([
          {
            title      :  t("OpcionesMenu.0.title"),
            link      :  t("OpcionesMenu.0.link"),
            img: require('@/assets/images/icons/Deportes.svg'),
            alt: t("OpcionesMenu.0.title"),
          },
          {
            title       :  t("OpcionesMenu.1.title"),
            link      :  t("OpcionesMenu.1.link"),
            img: require('@/assets/images/icons/casino.svg'),
            alt: t("OpcionesMenu.1.title"),
          },
          {
            title       :  t("OpcionesMenu.2.title"),
            link      :  t("OpcionesMenu.2.link"),
            img: require('@/assets/images/icons/caballos.svg'),
            alt: t("OpcionesMenu.2.title"),
          },
          {
            title       :  t("OpcionesMenu.3.title"),
            link      :  t("OpcionesMenu.3.link"),
            img: require('@/assets/images/icons/LiveBetting.svg'),
            alt: t("OpcionesMenu.3.title"),
          },
         /*{
            title       :  t("OpcionesMenu.4.title"),
            link      :  t("OpcionesMenu.4.link"),
            img: require('@/assets/images/icons/Gallos.svg'),
            alt: t("OpcionesMenu.4.title"),
          },*/
      ])
        // Observar los cambios de idioma y actualiza los slides
            const updateSlides = () => {
          menuOptions.forEach((opcion, index) => {
          opcion.title = t(`OpcionesMenu.${index}.title`);
          opcion.link = t(`OpcionesMenu.${index}.link`);
        });
      };
      // Escuchar los cambios de idioma y actualiza los slides
      watch(locale, () => {
        updateSlides();
      });
      return {
        menuOptions
      }
    },
  };





    // props: {
    //   items: {
    //     type: Array,
    //     required: true
    //   }
    // },
    // data() {
    //   return {
    //     isOpen: false
    //   }
    // }
  // };
</script>