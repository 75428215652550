<template>
  <div class="modal-backdrop" v-if="showModal">
    <div class="modal-content__heading">
      <h3>{{ $t("Bienvenida") }}</h3>
      <button class="close-button" @click="closeModal">
        <span class="material-symbols-rounded">cancel</span>
      </button>
    </div>
    <div class="modal-content">
      <!-- Contenido de tu modal aquí -->
      <form class="login__form" name="LoginForm" action="javascript:void(0)" onsubmit="BackEndLogin(this); return false">
        <div class="login__form--item">
          <label class="login__form--label" for="username">{{ $t("Usuario") }}</label>
          <input class="login__form--input" name="username" id="username" type="text" required/>
        </div>

        <div class="login__form--item">
          <label class="login__form--label" for="password">{{ $t("Contraseña") }}</label>
          <input class="login__form--input" type="password" name="password" id="password" required/>
        </div>
        
        <div class="text-center mt-2"> 
          <span name="msj_loading" id="id-login-loading"></span>
          <small class="text-danger" name="msj_error_lg"></small>
        </div>

        <div class="login__link">
          <a :href="`/reset-pass`">{{ $t("OlvideContraseña") }}</a>
        </div>
        
        <div class="login__btns-login">
          <input type="submit" class="btn-login secondary-button secondary-button__login" name="send" id="send" :value="$t('Ingresar')">
          <input type="hidden" name="BackEndUrl" :value="`https://betslip.${BackEndUrl}`">
          <router-link v-for="(opcion, index) in signUpBtn" :key="index" :to="opcion.link" class="secondary-button secondary-button__register-secondary" @click="closeModal">{{ opcion.title }}</router-link>
        </div>        
      </form>
    </div>
  </div>
</template>

<script>
  import { reactive, watch } from 'vue';
  import { useI18n } from 'vue-i18n';
  export default {
    name: 'ModalComponent',
    setup:() => {
      const { t, locale }  = useI18n();
      const BackEndUrl = "broadwaysports.mx";
      const signUpBtn = reactive ([
      {
            title      :  t("RegistroBtn.0.title"),
            link      :  t("RegistroBtn.0.link")
          },
      ])
      // Observar los cambios de idioma y actualiza los slides
      const updateSlides = () => {
        signUpBtn.forEach((opcion, index) => {
          opcion.title = t(`RegistroBtn.${index}.title`);
          opcion.link = t(`RegistroBtn.${index}.link`);
        });
      };
      // Escuchar los cambios de idioma y actualiza los slides
      watch(locale, () => {
        updateSlides();
      });
      return {
        BackEndUrl,
        signUpBtn
      }
    },
    data() {
      return {
        showModal: false
      }
    },
    methods: {
      openModal() {
        this.showModal = true;
      },
      closeModal() {
        this.showModal = false;
      }
    }
  }
</script>